import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../components/layout";
// Icons
import { IoDiamond, IoLogoEuro, IoHammer } from 'react-icons/io5';
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";


const About = () => {

    return (
        <Layout>
                <Seo
      title="A propos de Bike Safe... - BIKESAFE"
      description="Fabriquer des produits de qualité et au design innovant tout en offrant un excellent service clients chez les particuliers, sur les lieux de travail et dans les lieux publics..."
    />
            <Container>
                <Row className="mt-5 mb-5 text-center">
                    <Col sm="12">
                        <h1>A propos de Bike Safe...</h1>
                        <h3>Spécialisée et reconnue dans la fabrication de stationnement et de rangement pour vélos, Bike Safe conçoit de nombreux modèles.</h3>
                        <StaticImage placeholder="blurred"  src="../images/about-header.jpg" className="mt-4 mb-4 shadow fluid rounded" alt="Bikesafe Fabrication" />
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" sm="12">
                        <h2 className="float-lg-left subheading-product">NOTRE OBJECTIF :</h2>
                    </Col>
                    <Col lg="6" sm="12">
                        <p className="flow-text">
                            Fabriquer des produits de qualité et au design innovant tout en offrant un excellent service clients chez les particuliers, sur les lieux de travail et dans les lieux publics.
                        </p>
                        <ul>
                            <li className="border p-2 shade-on-hover">Service complet pour l’ensemble de notre gamme de produits en passant par les supports cycles trombone très pratiques aux parkings vélos pour particuliers.</li>
                            <li className="border p-2 shade-on-hover">Vente de nos produits à tout type de clientèle : entreprises, services publics, entrepreneurs, particuliers, etc.</li>
                            <li className="border p-2 shade-on-hover">Bien implanté sur notre secteur, nous sommes l’un des fournisseurs officiels de TFL (organisme public chargé des transports en commun à Londres).</li>
                            <li className="border p-2 shade-on-hover">Nous sommes les premiers à fabriquer des produits de stationnement pour vélos en acier recyclé. Pour plus d’informations, n’hésitez pas à consulter notre rubrique « gamme de produits recyclés ».</li>
                        </ul>
                    </Col>
                </Row>
                <Row className="mt-5 mb-5 text-center">
                    <Col sm="12">
                        <h3>Pour en savoir plus, découvrez ci-dessous comment nos supports bicyclettes sont fabriqués dans notre usine au Royaume-Uni :</h3>
                    </Col>
                </Row>
                <Row className="text-center">
                    <Col lg="4" sm="12">
                        <StaticImage placeholder="blurred"  src= "../images/factory/1.jpg" alt="Bikesafe Fabrication" className="mt-3 fluid rounded" />
                    </Col>
                    <Col lg="4" sm="12">
                        <StaticImage placeholder="blurred"  src= "../images/factory/2.jpg" alt="Bikesafe Fabrication" className="mt-3 fluid rounded" />
                    </Col>
                    <Col lg="4" sm="12">
                        <StaticImage placeholder="blurred"  src="../images/factory/3.jpg" alt="Bikesafe Fabrication" className="mt-3 fluid rounded" />
                    </Col>
                    <Col id="offsetColumnOne" className="d-none d-lg-block" lg="2" />
                    <Col lg="4" sm="12">
                        <StaticImage placeholder="blurred"  src="../images/factory/4.jpg" alt="Bikesafe Fabrication" className="mt-3 fluid rounded" />
                    </Col>
                    <Col lg="4" sm="12">
                        <StaticImage placeholder="blurred"  src="../images/factory/5.jpg" alt="Bikesafe Fabrication" className="mt-3 fluid rounded" />
                    </Col>
                    <Col id="offsetColumnTwo" className="d-none d-lg-block" lg="2" />
                </Row>
                <Row className="mt-5 mb-5 text-center">
                    <Col sm="12">
                        <h3>
                            Tous nos produits présentent certaines caractéristiques communes que nous estimons fondamentales lors d'un achat de mobilier urbain :
                        </h3>
                        <p className="flow-text mt-5"><IoDiamond/> Haute qualité</p>
                        <p className="flow-text mt-5"><IoHammer/> Aucune maintenance/Maintenance minimale</p>
                        <p className="flow-text mt-5"><IoLogoEuro/> Excellent rapport qualité-prix</p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )

}

export default About;